import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './@Auth/auth-material/auth.guard';

const routes: Routes = [
  {path:'', redirectTo:'auth', pathMatch:'full'},
  { path: 'auth', loadChildren: () =>import('./@Auth/auth.module').then((m) => m.AuthModule)},
  { path: 'master' ,canActivate: [AuthGuard] ,loadChildren: () =>import('./@Modules/master.module').then((m) => m.MasterModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
