import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

export interface AccessCrudConfig {
  moduleName: string;
  isChildren: boolean;
  identifierField?: string;
  identifierValue?: string;
  childIdentifierField?: string;
  childIdentifierValue?: string;
  crudOperation: 'create' | 'view' | 'edit' | 'delete';
}
@Component({
  selector: 'app-ag-grid-customized-cell',
  template: `
  <button
      type="button"
      (click)="onEditClick()"
      class="btn btn-default"
    >
      Edit <mat-icon>edit</mat-icon>
    </button>
    <button
      type="button"
      (click)="onDeleteClick()"
      class="btn btn-default"
    >
      Cancel
    </button>

<!--
    <button
      type="button"
      *ngIf="isNew == true"
      (click)="onEditClick()"
      data-action-type="view"
      class="btn btn-default"
    >
      Edit
    </button>

    <button
      type="button"
      *ngIf="isNew == false"
      (click)="onUpdateClick()"
      data-action-type="view"
      class="btn btn-default"
    >
      Update
    </button>

    <button
      type="button"
      *ngIf="isNew == false"
      (click)="onCancelClick()"
      data-action-type="view"
      class="btn btn-default"
    >
      Cancel
    </button> -->
  `,
  // templateUrl: './ag-grid-customized-cell.component.html',
  // styleUrls: ['./ag-grid-customized-cell.component.css']
})


export class AgGridCustomizedCellComponent implements ICellRendererAngularComp {
  // public params: any;
  // public isNew: any;
  // previousData: any;
  agInit(params: any): void {
    // this.params = params;
  }

  constructor() {
    // this.isNew = true;
  }

  // public invokeParentMethod() {
  //   this.params.context.componentParent.methodFromParent(
  //     `Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`
  //   );
  // }

  refresh(): boolean {
    return true;
  }

  onDeleteClick(){
    console.log("hiiii del");
  }

  onEditClick() {
    console.log("hiiii edit");
    // const index = this.params.node.rowIndex;
    // this.params.cancelOtherRowEditors(index);
    // this.isNew = false;
    // this.previousData = JSON.parse(JSON.stringify(this.params.node.data));
    // let cols = this.params.columnApi.getAllGridColumns();
    // let firstCol = {
    //   colId: '',
    // };
    // if (cols) {
    //   firstCol = cols[0];
    // }
    // let rowIndex = this.params.node.rowIndex;
    // this.params.api.setFocusedCell(rowIndex, firstCol.colId);
    // this.params.api.startEditingCell({
    //   rowIndex: rowIndex,
    //   colKey: 'row',
    // });
  }

  // onUpdateClick() {
  //   this.isNew = true;
  //   let obj: any = {};
  //   obj.type = 'update';
  //   this.params.api.stopEditing();
  //   obj.selectedData = [this.params.data];
  //   // update logic ....
  // }

  // public onCancelClick() {
  //   this.isNew = true;
  //   this.params.node.setData(this.previousData);
  //   this.params.api.stopEditing(true);
  // }

  // onDeleteClick() {
  //   const selectedData = [this.params.node.data];
  //   console.log(selectedData);
  //   this.params.api.applyTransaction({ remove: selectedData });
  // }
}
