import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthModal, AuthCredential, AuthOTPCredential } from './auth-modal/auth-modal';
import { take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from '@ckeditor/ckeditor5-utils';

const baseUrl = environment.baseUrl;
const apiRoutes = {
  login: () => `${baseUrl}/accounts/admin-login/`,
  verifyUser: () => `${baseUrl}/accounts/verify-user/`,
  loginWithOTP: () => `${baseUrl}/accounts/login/`,
  getNewAccess_token: () => `${baseUrl}/accounts/api/token/refresh/`,
  resendOtp: () => `${baseUrl}/accounts/resend-otp/`,
  logoutAndBlockToken: () => `${baseUrl}/accounts/logout/`,
};

interface LoginData {
  email: string;
  userID: number;
  _token: string;
  _retoken: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private HttpClient: HttpClient, private router: Router, private toaster:ToastrService) {}

  verifyUser(body: AuthCredential){
    return this.HttpClient.post<any>(apiRoutes.verifyUser(), body);
  };

  loginWith_OTP(body: AuthOTPCredential) {
    return this.HttpClient.post<any>(apiRoutes.loginWithOTP(), body).pipe(
      tap((res) => {
        const userData:LoginData = {
          email: res.user_email,
          userID: res.user_id,
          _token: res.token.access,
          _retoken: res.token.refresh,
        };
        this.adminAuthentication(userData);
        console.log(res, ' __Login User__');
      })
    );
  };

  setNewAccess_token(refresh_token:string){
    const body = {refresh:refresh_token};
    this.HttpClient.post<any>(apiRoutes.getNewAccess_token(),body).subscribe(
      (res)=>{
        const logindata: any = localStorage.getItem('loginUser');
        const loginUser = JSON.parse(logindata);
        let with_updateAccess_token = JSON.stringify({... loginUser, _token:res.access});
        localStorage.setItem('loginUser', with_updateAccess_token);
        this.toaster.warning('Please Refresh the page');
      },(err)=>{
        console.log(err);
        this.toaster.error('please login again','Session Expired');
        this.logout();
      }
    )
  }

  login(body: AuthCredential) {
    return this.HttpClient.post<any>(apiRoutes.login(), body).pipe(
      tap((res) => {
        const userData:LoginData = {
          email: res.user_email,
          userID: res.user_id,
          _token: res.token.access,
          _retoken: res.token.refresh,
        };
        this.adminAuthentication(userData);
        console.log(res, ' __Login User__');
      })
    );
  };

  resendOtp(data:any){
    return this.HttpClient.post<any>(apiRoutes.resendOtp(),data);
  };

  logoutAndBlockToken(){
    const {_retoken} = this.getUserSession();
    return this.HttpClient.post<any>(apiRoutes.logoutAndBlockToken(),{refresh:_retoken});
  };

  logout() {
    this.logoutAndBlockToken().subscribe((res)=>this.cleanUserSession(),(err)=>this.cleanUserSession());
  };

  cleanUserSession(){
    localStorage.removeItem('loginUser');
    this.router.navigate(['']);
  };

  getUserSession() {
    const userProfile: any = localStorage.getItem('loginUser');
    return JSON.parse(userProfile);
  }

  adminAuthentication(data: LoginData) {
    const { email, userID, _token, _retoken } = data;
    const User = new AuthModal(email, userID, _token, _retoken);
    let loginUser = JSON.stringify(User);
    localStorage.setItem('loginUser', loginUser);
  };
}
