import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, finalize, tap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService:AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const logindata: any = localStorage.getItem('loginUser');
    const loginUser = JSON.parse(logindata);

    let cookie: any = {};
    document.cookie.split(';').forEach((el) => {
      let [key, value] = el.split('=');
      cookie[key.trim()] = value;
    });

    if (!loginUser) {
      return next.handle(request);
    } else {
      const requestedUrl = request.url;
      let isVerifyRoute = requestedUrl.includes('/accounts/verify-user/');
      let isLoginRoute = requestedUrl.includes('/accounts/login/');
      let getNewToken = requestedUrl.includes('/accounts/api/token/refresh/');
      if (isVerifyRoute || isLoginRoute || getNewToken) {
        return next.handle(request);
      } else {
        const modifyReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${loginUser._token}`,
            // 'X-CSRFToken': cookie?.csrftoken,
          },
        });
        return next.handle(modifyReq).pipe( 
          tap({
            error: (err) => {
              if(err.status == 401){ 
                this._authService.setNewAccess_token(loginUser._retoken);
              };
            },
          })
        );
      };
    };
  };
}