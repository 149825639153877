import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { LeftNavigationComponent } from './left-navigation/left-navigation.component';
import { AgGridCustomizedCellComponent } from './ag-grid-customized-cell/ag-grid-customized-cell.component';
import { MaterialModule } from './modules/material.module';



@NgModule({
  declarations: [
    // HeaderComponent,
    // LeftNavigationComponent,
    AgGridCustomizedCellComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports :[
    // HeaderComponent,
    // LeftNavigationComponent
  ]
})
export class SharedModule { }
