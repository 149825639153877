export class AuthModal {
  constructor(
   public email: string,
   public userID: number,
   public _token: string,
   public _retoken: string
  ) {}
}
export interface AuthCredential {
  email: string;
  password: string;
}
export interface AuthOTPCredential {
  email: string;
  otp: string;
}
