import { Component,  OnInit, LOCALE_ID} from '@angular/core';
import { GridApi } from 'ag-grid-community';

// import { HttpClient } from '@angular/common/http';
import { ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { Observable, elementAt } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'ikoo-admin';
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  rowClass:any;
  rowData:any;
  public editType: any; // = 'fullRow';
  // public rowData: any = [];

  // public api: GridApi;
  // public gridApi: any;
  // public rowData: any = [];
  // public editType: any = 'fullRow';
  // domLayout = 'autoHeight';
   gridApi:GridApi


  constructor() {
    this.rowClass = 'rowClass'
  }

  ngOnInit(): void {
    this.rowData = [
          { make: 'abc', model: 'm1', price: '011' },
          { make: 'abc', model: 'm2', price: '011' },
          { make: 'abc', model: 'm2', price: '011' },
        ];
  }


  // // Example of consuming Grid Event
  // onCellClicked( e: CellClickedEvent): void {
  //   console.log('cellClicked', e);
  // }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }


  public columnDefs = [
    {
      headerName: 'S. no',

      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "grid-cell-centered",
      flex:1,
      minWidth:150
    },
    {
      headerName: 'Make',
      field: 'make',
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "grid-cell-centered",
      flex:1,
      minWidth:150
    },

    {
      headerName: 'Model',
      field: 'model', sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "grid-cell-centered",
      flex:1,
      minWidth:150
    },
    {
      headerName: 'Price',
      field: 'price', sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "grid-cell-centered",
      flex:1,
      minWidth:150
    },

    {
      headerName: 'Action',
      field: 'role_master_name',
      flex:1,
      minWidth:150,
      cellRenderer: this.ActionBtns.bind(this),
      cellRendererParams: {
        className: 'mat-blue',
        hideRequestButton: true,
        hideDetailsButton: false,
        hideDownloadIcon: false,
        showCustomIcon: false, // Hide attachment icon
      },
    }
  ];

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    console.log(this.gridApi);

  }

  onCellClicked(e:any){
    console.log();
  }

  ActionBtns(e:any){
    let delteBtn = document.createElement('button');
    delteBtn.innerHTML = 'Delete';
    delteBtn.addEventListener('click',()=>{
      console.log('delete');

    })
    return delteBtn
  }
}
