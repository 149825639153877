import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './@Shared/modules/material.module';
// import { HeaderComponent } from './@Shared/header/header.component';
// import { LeftNavigationComponent } from './@Shared/left-navigation/left-navigation.component';
import { SharedModule } from './@Shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule} from 'ngx-bootstrap/tabs';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http'
import { AuthInterceptor } from './@Auth/auth-material/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
// import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
// ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule]);
import { NgxMatFileInputModule } from '@angular-material-components/file-input/lib/file-input.module';
import { NgxOrgChartModule } from '@fdjlss/ngx-org-chart';
// import { NgxEditorModule } from 'ngx-editor';


@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    // LeftNavigationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    HttpClientModule,
    HttpClientXsrfModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxMatFileInputModule,
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxOrgChartModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
